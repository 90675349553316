import { createContext, useContext, useEffect, useState } from 'react';
import axiosClient from '../utils/axios';
import initFirebaseNotifications from '../utils/firebase';
import useSmartToast from './SmartToast';
import { useRouter } from 'next/router';

const AuthContext = createContext<AuthProps>(null);

export const useAuth = (): AuthProps => {
  return useContext(AuthContext);
};

type LoginStatus = 'LOGGED_IN' | 'LOGGED_OUT' | 'UNKNOWN';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<User>(null);
  const [token, setToken] = useState<string>(null);
  const [loginStatus, setLoginStatus] = useState<LoginStatus>('UNKNOWN');
  const toast = useSmartToast();
  const router = useRouter();

  const initFirebaseNotificationsAfterLogin = async () => {
    const token = await initFirebaseNotifications();
    if (!token) {
      sendFCMToken(token);
    }
  };

  const login = async (
    email: string,
    password: string,
    loginType: 'admin' | 'vendor' | 'client',
  ) => {
    const res = await axiosClient.post('/auth/login', {
      email,
      password,
      type: loginType,
    });

    if (res.status === 200) {
      localStorage.setItem('procura_access_token', res.data.accessToken);
      localStorage.setItem('procura_refresh_token', res.data.refreshToken);
      localStorage.setItem('procura_user', JSON.stringify(res.data.user));
      setToken(res.data.token);
      setUser(res.data.user);
      setLoginStatus('LOGGED_IN');
      await initFirebaseNotificationsAfterLogin();
    }

    return true;
  };

  const sendFCMToken = async (token = null) => {
    await axiosClient.post('/auth/fcm-token', {
      fcmToken: token || localStorage.getItem('fcm_token'),
    });
  };

  const refresh = async () => {
    try {
      const res = await axiosClient.get('/auth/refresh', {
        headers: {
          Authorization: `Bearer ${token}`,
          'refresh-token': localStorage.getItem('procura_refresh_token'),
        },
      });

      if (res.status == 200) {
        localStorage.setItem('procura_user', JSON.stringify(res.data.user));
        setUser(res.data.user);

        if (res.data.accessToken) {
          localStorage.setItem('procura_access_token', res.data.accessToken);
          setToken(res.data.accessToken);
        }

        if (res.data.refreshToken) {
          localStorage.setItem('procura_refresh_token', res.data.refreshToken);
        }

        return true;
      }
    } catch (error) {
      if (error.response?.status == 401 || error.response?.status == 403) {
        logout();
        toast.error(null, 'Session expired, please login again');
        router.push('/login');
      } else if (error.response?.status == 404) {
        logout();
        toast.error(null, 'Logged in user not found, please login again');
        router.push('/login');
      }

      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('procura_access_token');
    localStorage.removeItem('procura_refresh_token');
    localStorage.removeItem('procura_user');
    localStorage.removeItem('fcm_token');
    setToken(null);
    setUser(null);
    setLoginStatus('LOGGED_OUT');
  };

  const checkTokenPresent = () => {
    if (localStorage.getItem('procura_access_token') != null) {
      setToken(localStorage.getItem('procura_access_token'));
    } else {
      setLoginStatus('LOGGED_OUT');
    }

    if (localStorage.getItem('procura_refresh_token') != null) {
      setToken(localStorage.getItem('procura_refresh_token'));
    } else {
      setLoginStatus('LOGGED_OUT');
    }

    if (localStorage.getItem('procura_user') != null) {
      const userData = JSON.parse(localStorage.getItem('procura_user'));
      setUser({
        ...userData,
        permissions: userData.permissions || [],
        accountType: userData.accountType || 'PROCURA',
      });

      refresh().then(success => {
        if (success) {
          setLoginStatus('LOGGED_IN');
        } else {
          setLoginStatus('LOGGED_OUT');
        }
      });
    } else {
      setLoginStatus('LOGGED_OUT');
    }
  };

  const checkTokenExpiry = async () => {
    try {
      const res = await axiosClient.get('/auth/check-expiry', {
        headers: {
          Authorization: `Bearer ${token}`,
          'refresh-token': localStorage.getItem('procura_refresh_token'),
        },
      });
      // If Session expires in an hour, notify user
      if (res.data.expiryTime)
        toast.info('Your Session will expire in 15 mins');
    } catch (error) {
      if (error.response?.status == 401) {
        toast.error(null, 'Session expired, please login again');
      } else if (error.response?.status == 404) {
        toast.error(null, 'Logged in user not found, please login again');
      }
      logout();
      router.push('/login');
    }
  };

  useEffect(() => {
    checkTokenPresent();
    if (loginStatus == 'LOGGED_IN') checkTokenExpiry();
  }, []);

  const hasPermission = (permission: UserPermission) => {
    return (
      user?.permissions?.includes('superadmin') ||
      user?.permissions?.includes(permission) ||
      false
    );
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token,
        checkTokenExpiry,
        loginStatus,
        hasPermission,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthProps {
  login: (email: string, password: string, type: string) => Promise<any>;
  logout: () => void;
  user: User;
  token: string;
  checkTokenExpiry: () => void;
  loginStatus: LoginStatus;
  hasPermission: (permission: UserPermission) => boolean;
  refresh: () => Promise<boolean>;
}

interface User {
  id: string;
  name: string;
  email: string;
  type: string;
  accountType: 'PROCURA' | 'SAAS';
  organization?: {
    id: string;
    name: string;
    requirePodVerification: boolean;
    requireStockTracking: boolean;
  };
  role?: {
    id: string;
    name: string;
    canCreateOrder: boolean;
    canCreateOrderRequest: boolean;
    canViewAllAddresses: boolean;
  };
  permissions: UserPermission[];
  profileCompleted?: boolean;
  enabled?: boolean;
}

const allPermissions = {
  superadmin: {
    group: 'admin',
    label: 'Super Admin',
    description: 'Full Access',
  },
  'clients.create': {
    group: 'clients',
    label: 'Create Clients',
    description: 'Create new clients, accounts and addresses',
  },
  'clients.manage': {
    group: 'clients',
    label: 'Manage Clients',
    description: 'Edit clients, accounts and addresses',
  },
  'clients.delete': {
    group: 'clients',
    label: 'Delete Clients',
    description: 'Delete clients, accounts and addresses',
  },
  'orders.manage': {
    group: 'orders',
    label: 'Manage Orders',
    description: 'Create, edit and manage orders',
  },
  'orders.cancel': {
    group: 'orders',
    label: 'Cancel Orders',
    description: 'Cancel orders',
  },
  'shop.manage': {
    group: 'shop',
    label: 'Manage Shop',
    description: 'Manage shop products, categories and orders',
  },
  'quotations.manage': {
    group: 'quotation',
    label: 'Access Quotation Maker',
    description: 'Create, edit and manage quotations on Quotation Maker',
  },
  'links.manage': {
    group: 'links',
    label: 'Manage Links',
    description: 'Create, edit, manage and delete custom links',
  },
  'reports.general': {
    group: 'reports',
    label: 'View Reports',
    description: 'View reports and analytics',
  },
  'reports.sensitive': {
    group: 'reports',
    label: 'View Sensitive Reports',
    description: 'View sensitive reports and analytics',
  },
  'inquiry.all': {
    group: 'inquiry',
    label: 'Manage Inquiries',
    description: 'Create and Update Inquiries',
  },
};

export type UserPermission = keyof typeof allPermissions;
